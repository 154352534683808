import { ResetPasswordFormState, getResetPasswordFormDefaultValues, getResetPasswordFormYupResolver } from "../../service/FormBuilder";
import { useForm } from "react-hook-form";
import sweetAlert from 'sweetalert2'
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonMenu, IonNote, IonPage } from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import Header2 from "../../components/Header2";
import Header from "../../components/Header";
import { getValue } from "../../service/LocalStorage";
import { logout } from "../../service/api";
import { useHistory } from "react-router";

const C_removeAccount: React.FC = () => {
    
    const history = useHistory()
    
    
    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormState>({
        resolver: getResetPasswordFormYupResolver(),
        defaultValues: getResetPasswordFormDefaultValues(),
    });
    async function handleLogout(){
      await logout("guest",setRoleState,history)
    }  
    const removeUser = async () => {
        
        try {
            let token = await getValue("token")
            
            let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/delUser`,{
                headers:{
                  "Content-type":"application/json",
                  'Authorization': `Bearer ${token}`
                },
                method:"PUT",
                body:""
            })
            let json = await res.json()
            
            if(!json.isErr){
                handleLogout()
                sweetAlert.fire({
                    icon: 'success',
                    title: 'Reset Password',
                    text:"success",
                    showConfirmButton: false,
                    timer: 1500
                  }) 
            }    
        } catch (error:any) {
            sweetAlert.fire({
                icon: 'info',
                title: 'Message',
                text:error.message,
                showConfirmButton: false,
                timer: 1500
              })    
        }
        
        
    };
    
    return (
      <>
      <IonMenu side="end" contentId="main-content">
        <Header2 name={"刪除帳戶"} />
      </IonMenu> 
        <IonPage id="main-content">
          <Header name={"刪除帳戶"} />
          <IonContent fullscreen className="ion-padding">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            {/* <IonItem fill="outline">
                <IonLabel position="floating">密碼</IonLabel>
                <IonInput className="text" clearInput={true} {...register("password")} aria-label="password" placeholder="密碼"></IonInput>
            </IonItem>
            <IonNote>{errors.password?.message}</IonNote> */}
            <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
            <IonButton className="formBtn" shape="round" onClick={removeUser}>確認刪除帳戶</IonButton>
            </div>
            {/* </form> */}
          </IonContent>
        </IonPage>
      </>
    );
  };
  
  export default C_removeAccount;
  

function setRoleState(valOrUpdater: { role: string | null; } | ((currVal: { role: string | null; }) => { role: string | null; })): void {
  throw new Error("Function not implemented.");
}
  